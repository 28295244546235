<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>LISTADO DE ABOGADOS REGISTRADOS EN EL SISTEMA</h6>
        </div>
        <div class="col">
          <button  class="btn bg-gradient-success float-end" @click="$router.push('/create-lawyer')"> Crear nuevo abogado</button>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row justify-content-end">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="Buscar por nombre" v-model="pagination.search" @keyup="manualSearch">
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <select name="per_page" id="per_page" v-model="pagination.limit" class="form-control" @click="reloadPaginate(1)">
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover table-striped table-bordered border">
          <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Biografia</th>
            <th scope="col">Opciones</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in arrayLawyers" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
            <td><div class="limited-width_70">
              {{ item.description.slice(0,100) }}
            </div></td>
            <td>
              <button class="btn btn-outline-success btn-sm mb-0" @click="viewDetail(item._id)">
                ver
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <p class="text-xs font-weight-bold mb-0">total rows: {{pagination.totalRows}}</p>
        </div>
        <div>
          <v-pagination
              v-model="pagination.page"
              :pages="pagination.pages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="reloadPaginate"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import {get} from "@/utils/httpRequest";

export default {
  name: "Index",
  data: () => ({
    arrayLawyers: [],
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 20,
      search: '',
      totalRows: 0
    }
  }),
  mounted() {
    this.getUsers();
  },
  methods: {
    reloadPaginate(page){
      this.pagination.page = page
      this.getUsers()
    },
    manualSearch(){
      clearTimeout(this.pagination.searchTimer);
      this.pagination.searchTimer = setTimeout(() => {
        this.reloadPaginate(1)
      }, 2000);
    },
     viewDetail(_id){
      this.$router.push({name:'detail-lawyer', query: {id: _id}})
    },

    async  getUsers () {
      const {data} = await get('lawyer/get-all-lawyers')
      this.arrayLawyers = data
    }
  }
}
</script>
<style scoped>

</style>
